import React from "react";
import { LinkUnderlineComponent } from "./LinkUnderlineComponent";
import { CenterComponent } from "./CenterComponent";
import MarginComponent from "./MarginComponent";

export function CardPriceComponent({ price, show }) {
  const details = show && (
    <MarginComponent small="sm:mt-6" margin="mt-4">
      <CenterComponent>
        <LinkUnderlineComponent href={`/${price.slug.current}`}>
          Подробнее
        </LinkUnderlineComponent>
      </CenterComponent>
    </MarginComponent>
  );

  return (
    <div className="relative h-full p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
      <div className="flex-1">
        <h3 className="font-semibold text-gray-900">{price.name}</h3>
        <p className="absolute top-0 py-1.5 px-4 bg-green-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
          Популярное
        </p>
        <p className="mt-4 flex items-baseline text-gray-900">
          <span className="text-lg font-extrabold tracking-tight">
            {price.cost}
          </span>
          <span className="ml-1 text-sm font-semibold">/ {price.days}</span>
        </p>
      </div>
      {details}
    </div>
  );
}
